import { isDefined } from "@clipboard-health/util-ts";
import { differenceInSeconds, parseISO } from "date-fns";
import { useEffect, useState } from "react";

export function useCountdownToDate(endDateIso: string | undefined) {
  const [cancellationSecondsLeft, setCancellationSecondsLeft] = useState<number>(0);
  useEffect(() => {
    if (!isDefined(endDateIso)) {
      // If worker clocks-in when timer is running, we need to set this to 0 to not show the countdown anymore
      setCancellationSecondsLeft(0);
      return undefined;
    }

    const cancellationWithoutPenaltyThresholdDate = parseISO(endDateIso);

    const updateCancelTimerInterval = (timeInvterval: number) => {
      const secondsLeft = differenceInSeconds(cancellationWithoutPenaltyThresholdDate, new Date());
      setCancellationSecondsLeft(secondsLeft);
      if (secondsLeft <= 0) {
        setCancellationSecondsLeft(0);
        clearInterval(timeInvterval);
      }
    };

    const cancelTimerInterval = setInterval(updateCancelTimerInterval, 1000);
    updateCancelTimerInterval(cancelTimerInterval); // Run immediately on effect execution

    return () => {
      clearInterval(cancelTimerInterval);
    };
  }, [endDateIso, setCancellationSecondsLeft]);

  return cancellationSecondsLeft;
}
