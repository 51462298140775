import { isDefined } from "@clipboard-health/util-ts";
import { matchPath, useLocation } from "react-router-dom";

import { useDefinedWorker } from "../Worker/useDefinedWorker";
import { ModalRoute } from "./Navigation/ModalRoute";
import { ModalTransitionSwitch } from "./Navigation/ModalTransitionSwitch";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH,
} from "./paths";
import { LegacyQuizFlowContainer } from "./Quiz/LegacyQuizFlowContainer";
import { LocationPermissionsBottomSheet } from "./Shift/Bookability/LocationPermissionsBottomSheet";
import { UndoBookingConfirmationBottomSheet } from "./Shift/Booking/UndoBookingConfirmationBottomSheet";
import { BookingConfirmationBottomSheet } from "./Shift/BookingConfirmationBottomSheet/BookingConfirmationBottomSheet";
import { ShiftBottomSheet } from "./Shift/BottomSheet/Sheet";
import { BreakConfirmationBottomSheet } from "./Shift/BreakConfirmationBottomSheet/BreakConfirmationBottomSheet";
import { type ShiftModalRoutePathParams } from "./types";
import { useGetShiftModalShiftData } from "./useGetShiftModalShiftData";
import { useShiftModalsDataContext } from "./useShiftModalsDataContext";

/**
 * ShiftModalRoutes handles all shift-related modals in the application.
 *
 * Key characteristics:
 * 1. Can be rendered independently or on top of workplace modals
 * 2. Uses baseNavigationPathOverride when rendered on top of workplace modals
 * 3. Shares common transition animations with other modals
 *
 * Common flows:
 * - Direct shift modal: /shift-discovery/list/shift/:id
 * - Nested shift modal: /shift-discovery/list/workplace/:id/open-shifts/shift/:id
 */
export function ShiftModalRoutes() {
  const {
    openShift,
    baseNavigationPath: defaultBaseNavigationPath,
    baseNavigationPathOverride,
  } = useShiftModalsDataContext();

  const isNestedModalContext = isDefined(baseNavigationPathOverride);
  const baseNavigationPath = isNestedModalContext
    ? `${defaultBaseNavigationPath}/${baseNavigationPathOverride}`
    : defaultBaseNavigationPath;

  const { pathname } = useLocation();
  const match = matchPath<ShiftModalRoutePathParams>(pathname, {
    path: [
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH}`,
      `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH}`,
    ],
    exact: true,
    strict: false,
  });

  const { userId: workerId } = useDefinedWorker();

  const shouldFetchShift = isDefined(match?.params.shiftId);
  const shiftIdToFetch = match?.params.shiftId ?? openShift?.id;

  const { data, isLoading, isError } = useGetShiftModalShiftData(
    {
      shiftId: shiftIdToFetch!,
      workerId,
    },
    {
      enabled: shouldFetchShift,
    }
  );

  const { shift, bookabilityStatus } = data;

  return (
    <ModalTransitionSwitch>
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_MODAL_PATH}`}
        render={({ modalState }) => {
          return (
            <ShiftBottomSheet
              shift={shift}
              bookabilityStatus={bookabilityStatus}
              isLoading={shouldFetchShift && isLoading}
              isError={isError}
              modalState={modalState}
            />
          );
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <BookingConfirmationBottomSheet shift={shift} modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <BreakConfirmationBottomSheet shift={shift} modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <UndoBookingConfirmationBottomSheet modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH}`}
        render={({ modalState }) => <LocationPermissionsBottomSheet modalState={modalState} />}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <LegacyQuizFlowContainer modalState={modalState} shift={shift} />;
        }}
      />
    </ModalTransitionSwitch>
  );
}
