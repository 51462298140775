import { Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";

import { ShiftDiscoverySearchMode } from "../OpenShifts/ShiftAction/api/useClaimShift";
import { Button } from "./components/Button";
import { OpenFiltersButton } from "./Filters/OpenFiltersButton";
import { SHIFT_DISCOVERY_LIST_PATH, SHIFT_DISCOVERY_MAP_PATH } from "./paths";
import { useIsShiftDiscoverySearchMode } from "./utils/useShiftDiscoverySearchMode";

export function ShiftDiscoverySearchModeSwitcher() {
  const isMapMode = useIsShiftDiscoverySearchMode(ShiftDiscoverySearchMode.MAP);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      sx={(theme) => ({
        position: "absolute",
        bottom: theme.spacing(1),
        left: 0,
        right: 0,
        margin: "0 auto",
      })}
    >
      {isMapMode ? (
        <Button
          size="medium"
          variant="contained"
          sx={(theme) => ({ boxShadow: theme.shadows[3] })}
          href={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`}
        >
          View List
        </Button>
      ) : (
        <Button
          size="medium"
          variant="contained"
          sx={(theme) => ({ boxShadow: theme.shadows[3] })}
          href={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_MAP_PATH}`}
        >
          View Map
        </Button>
      )}

      <OpenFiltersButton />
    </Stack>
  );
}
