import { isDefined } from "@clipboard-health/util-ts";
import { useToast } from "@src/appV2/lib";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { useCancelShift } from "@src/appV2/Shifts/Shift/ShiftState/api/useCancelShift";
import { type CancelShiftRequest } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { useMutation } from "@tanstack/react-query";

import { LoadingButton } from "../../components/LoadingButton";
import { SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH } from "../../paths";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";
import { formatToMinutesAndSeconds } from "../../utils/formatToMinutesAndSeconds";
import { useCountdownToDate } from "./useCancellationTimer";

interface UndoBookingButtonProps {
  shiftId: string;
}

export function UndoBookingButton(props: UndoBookingButtonProps) {
  const { shiftId } = props;
  const { navigateToModal } = useShiftModalsDataContext();

  const { showErrorToast } = useToast();

  const { data } = useGetShift(shiftId, { enabled: isDefined(shiftId) });

  const shiftWithDetails = data?.response;

  const { mutateAsync: cancelShift } = useCancelShift({
    meta: {
      userErrorMessage: "Something went wrong while cancelling shift",
    },
  });

  const { mutateAsync, isLoading: isLoadingCancelShift } = useMutation(async () => {
    if (!isDefined(shiftWithDetails)) {
      return;
    }

    const payload: CancelShiftRequest = {
      shiftId,
      reasonType: "NONE",
      reasonDescription: "Undo Booking",
      isNative: true,
    };

    try {
      await cancelShift(payload);
      navigateToModal(SHIFT_DISCOVERY_SHIFT_UNDO_BOOKING_MODAL_PATH, {
        shiftId,
      });
    } catch (error: unknown) {
      const errorMessage =
        (error as { response?: { data?: { displayableMessage?: string } } }).response?.data
          ?.displayableMessage ?? "Something went wrong while cancelling this shift.";
      showErrorToast(errorMessage);
    }
  });

  const secondsLeft = useCountdownToDate(shiftWithDetails?.cancellationWithoutPenaltyThresholdTime);

  return (
    <LoadingButton
      disabled={secondsLeft <= 0}
      isLoading={isLoadingCancelShift}
      size="small"
      variant="outlined"
      onClick={async () => {
        await mutateAsync();
      }}
    >
      Undo booking {formatToMinutesAndSeconds(secondsLeft)}
    </LoadingButton>
  );
}
