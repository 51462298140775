import { Text } from "@clipboard-health/ui-react";
import { Slider, Stack } from "@mui/material";

import {
  aggregateRatingBreakdowns,
  type RatingBreakdown,
} from "../utils/aggregateRatingBreakdowns";

interface RatingsBreakdownProps {
  ratingBreakdowns: RatingBreakdown[];
}

export function RatingsBreakdown(props: RatingsBreakdownProps) {
  const { ratingBreakdowns } = props;

  const aggregatedBreakdowns = aggregateRatingBreakdowns(ratingBreakdowns);

  return (
    <Stack spacing={1}>
      {aggregatedBreakdowns.map(({ rating, percentage }) => (
        <Stack key={rating} direction="row" spacing={3} alignItems="center">
          <Text semibold variant="caption">
            {rating}
          </Text>

          <Slider
            disabled
            value={percentage}
            max={100}
            size="small"
            sx={{ "&.Mui-disabled": { color: "unset" } }}
          />
        </Stack>
      ))}
    </Stack>
  );
}
