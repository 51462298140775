import { ListItem, ListItemIcon, ListItemText } from "../../components/List";
import { DateWithDuration } from "../../DateTime/DateWithDuration";
import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { type TimeSlot } from "../OpenShifts/types";
import { iconTypeByTimeSlot } from "../TimeSlotIcon";

interface ShiftBottomSheetDateTimeInfoListItemProps {
  timeSlot: TimeSlot;
  startDate: Date;
  endDate: Date;
  durationInHours: number;
}

export function ShiftBottomSheetDateTimeInfoListItem(
  props: ShiftBottomSheetDateTimeInfoListItemProps
) {
  const { timeSlot, startDate, endDate, durationInHours } = props;

  return (
    <ListItem>
      <ListItemIcon iconType={iconTypeByTimeSlot[timeSlot]} />

      <ListItemText
        disableTypography
        primary={<TimeRangeLabel variant="h5" dateRange={{ startDate, endDate }} />}
        secondary={
          <DateWithDuration
            startDate={startDate}
            durationInHours={durationInHours}
            variant="body2"
          />
        }
      />
    </ListItem>
  );
}
