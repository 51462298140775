import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/ShiftDiscovery/components/BottomSheet";
import { DialogFooter } from "@src/appV2/ShiftDiscovery/components/DialogFooter";
import { Illustration } from "@src/appV2/ShiftDiscovery/components/Illustration";
import { MyShiftCard } from "@src/appV2/ShiftDiscovery/Shift/Card/MyShiftCard";
import { type ShiftWithType } from "@src/appV2/ShiftDiscovery/Shift/types";

import { UndoBookingButton } from "../Booking/UndoBookingButton";

interface BookingConfirmationBottomSheetProps {
  modalState: UseModalState;
  shift: ShiftWithType;
}

// TODO: Implement undo booking functionality
export function BookingConfirmationBottomSheet(props: BookingConfirmationBottomSheetProps) {
  const { modalState, shift } = props;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent sx={{ backgroundColor: "unset", py: 8, mt: 8 }}>
        <Stack direction="column" alignItems="center" spacing={9}>
          <Illustration type="shift-booked" />
          <Title variant="h2" component="h2" sx={{ textAlign: "center", width: "14rem" }}>
            Your shift is booked!
          </Title>
          <MyShiftCard outlined shift={shift} />
          <UndoBookingButton shiftId={shift._id} />
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
