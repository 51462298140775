import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
import { isIosPlatform } from "@src/appV2/lib";
import { requestFullLocationAccess } from "@src/appV2/Location/utils/permissions";
import { BottomSheet } from "@src/appV2/ShiftDiscovery/components/BottomSheet";
import { DialogFooter } from "@src/appV2/ShiftDiscovery/components/DialogFooter";
import { useHistory } from "react-router-dom";

import { Illustration } from "../../components/Illustration";
import { TextButton } from "../../components/TextButton";
import { LocationPermissionsUsesList } from "./LocationPermissionsUsesList";

interface LocationPermissionsBottomSheetProps {
  modalState: UseModalState;
}

export function LocationPermissionsBottomSheet(props: LocationPermissionsBottomSheetProps) {
  const { modalState } = props;
  const history = useHistory();

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              history.goBack();
              void requestFullLocationAccess();
            }}
          >
            Go To Settings
          </Button>
          <TextButton
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Not Now
          </TextButton>
        </DialogFooter>
      }
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Stack direction="column" alignItems="center" px={2} pb={10} spacing={8}>
          <Illustration type="location" />
          <Title variant="h3" component="h3">
            Enable location permissions
          </Title>
          <Text
            color={(theme) => theme.palette.text.secondary}
            sx={{ textWrap: "balance" }}
            variant="body1"
          >
            You must enable the{" "}
            <b>
              &quot;{isIosPlatform() ? "Always" : "Allow all the time"} and Precise Location&quot;{" "}
            </b>
            permissions to book shifts and access the Clipboard Health marketplace. We collect and
            use this location information for:
          </Text>
          <LocationPermissionsUsesList />
          <TextButton size="small" href="https://clipboardhealth.com/privacy-policy">
            Read our privacy policy
          </TextButton>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
