import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { CbhIcon } from "../../components/CbhIcon";

export function LocationPermissionsUsesList() {
  return (
    <Stack direction="column" px={6} spacing={6} textAlign="left">
      <Stack alignItems="center" direction="row" spacing={4}>
        <CbhIcon type="coin" />
        <Text bold variant="body1">
          Verifying completion of shifts and getting you paid
        </Text>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={4}>
        <CbhIcon type="search" />
        <Text bold variant="body1">
          Show you high-paying and urgent shifts near you
        </Text>
      </Stack>
      <Stack alignItems="center" direction="row" spacing={4}>
        <CbhIcon type="building" />
        <Text bold variant="body1">
          Confirming to facilities when you are on your way to a shift
        </Text>
      </Stack>
    </Stack>
  );
}
