import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { isMandatoryBreakPolicyNote } from "@src/appV2/Facilities/types";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import {
  useDeviceGeoLocationIfAllowed,
  useIsDeviceLocationPermissionGranted,
} from "@src/appV2/Location/deviceLocation/geoLocation";
import {
  type ShiftDiscoverySearchMode,
  useClaimShift,
} from "@src/appV2/OpenShifts/ShiftAction/api/useClaimShift";
import { useGetPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useGetPolicyAcknowledgement";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";

import { LoadingButton } from "../../components/LoadingButton";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
} from "../../paths";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";
import { type ShiftWithType } from "../types";

interface BookShiftButtonProps {
  shift: ShiftWithType;
  searchMode: ShiftDiscoverySearchMode;
  onBook: () => void;
}

// TODO: Following features need to be ported: Rate negotiation, request non-IP shift, conflicting shift invites
export function BookShiftButton({ shift, searchMode, onBook }: BookShiftButtonProps) {
  const { showErrorToast } = useToast();
  // We need the device's geolocation, otherwise we can't let the user claim the shift
  const {
    data: isDeviceLocationPermissionGranted,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();
  const geolocationIsUnavailable = !isDeviceLocationPermissionGranted && isCapacitorPlatform();
  // if the query is disabled, 'isLoading' is true until the query runs and we have data
  // this can cause an infinite spinner if location permissions are not given, as
  // useDeviceGeoLocationIfAllowed will always be isLoading === true. Therefore,
  // we need to use isInitialLoading instead
  // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading
  const { data: deviceGeolocation, isInitialLoading: isDeviceGeolocationLoading } =
    useDeviceGeoLocationIfAllowed({
      enabled: !geolocationIsUnavailable,
    });

  const { data: facilityNotesData, isInitialLoading: isFacilityNotesLoading } = useFacilityNotes(
    shift.facilityId ?? "",
    { enabled: isDefined(shift.facilityId) }
  );
  const { noteId: breakPolicyNoteId } =
    facilityNotesData?.find((note) => isMandatoryBreakPolicyNote(note)) ?? {};

  const { data: policyAcknowledgementData, isInitialLoading: isPolicyAcknowledgementLoading } =
    useGetPolicyAcknowledgement(
      {
        noteId: breakPolicyNoteId ?? "",
        policyAcknowledgementAction: NoteAcknowledgementAction.BOOK_SHIFT,
      },
      { enabled: isDefined(breakPolicyNoteId) }
    );

  const requiresBreakPolicyAcknowledgement =
    policyAcknowledgementData?.data.length === 0 && isDefined(breakPolicyNoteId);

  const { mutateAsync: claimShift, isLoading: isClaimShiftLoading } = useClaimShift();

  const { navigateToModal } = useShiftModalsDataContext();

  const isLoading =
    isDeviceLocationPermissionGrantedLoading ||
    isDeviceGeolocationLoading ||
    isClaimShiftLoading ||
    isPolicyAcknowledgementLoading ||
    isFacilityNotesLoading;

  async function attemptBookingShift() {
    if (geolocationIsUnavailable) {
      navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
      return;
    }

    if (requiresBreakPolicyAcknowledgement) {
      navigateToModal(SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH, {
        shiftId: shift._id,
        noteId: breakPolicyNoteId,
      });
      return;
    }

    if (!shift.offer?.id) {
      showErrorToast(
        "Could not load price for booking this shift. Please re-open the app and try again."
      );
      return;
    }

    try {
      await claimShift({
        shiftId: shift.shiftId ?? shift._id,
        offerId: shift.offer.id,
        searchMode,
        latitude: deviceGeolocation?.geoLocation?.latitude,
        longitude: deviceGeolocation?.geoLocation?.longitude,
      });
      onBook();
    } catch (error: unknown) {
      const errorMessage =
        (error as { response?: { data?: { displayableMessage?: string } } }).response?.data
          ?.displayableMessage ??
        "Something went wrong while booking this shift. Please try again.";
      showErrorToast(errorMessage);
    }
  }

  return (
    <LoadingButton
      fullWidth
      size="large"
      variant="contained"
      isLoading={isLoading}
      onClick={async () => {
        await attemptBookingShift();
      }}
    >
      Book this shift
    </LoadingButton>
  );
}
