import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Button, DialogContent, Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/ShiftDiscovery/components/BottomSheet";
import { DialogFooter } from "@src/appV2/ShiftDiscovery/components/DialogFooter";

import { Illustration } from "../../components/Illustration";

interface UndoBookingConfirmationBottomSheetProps {
  modalState: UseModalState;
}

export function UndoBookingConfirmationBottomSheet(props: UndoBookingConfirmationBottomSheetProps) {
  const { modalState } = props;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter orientation="vertical">
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              modalState.closeModal();
            }}
          >
            Okay!
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack
          direction="column"
          alignItems="center"
          spacing={8}
          sx={{ textAlign: "center", textWrap: "balance" }}
        >
          <Illustration type="undo" />
          <Title variant="h2" component="h2">
            Your booking is cancelled
          </Title>
          <Text variant="body1">
            No problem! Since you cancelled this booking within the 5-minute grace period, there is
            no penalty.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
