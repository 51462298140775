import { Rating as MuiRating, type RatingProps as MuiRatingProps } from "@mui/material";

import { CbhIcon, type IconSize } from "../components/CbhIcon";

interface RatingProps extends Omit<MuiRatingProps, "icon" | "emptyIcon"> {}

const ratingSizeToIconSize: Record<Required<MuiRatingProps>["size"], IconSize> = {
  small: "xSmall",
  medium: "medium",
  large: "large",
};

// TODO: When we fully roll out new design system, add eslint ignore pattern for MUI Rating
export function Rating(props: RatingProps) {
  const { size = "medium", ...restProps } = props;

  return (
    <MuiRating
      {...restProps}
      icon={<CbhIcon type="star-filled" size={ratingSizeToIconSize[size]} />}
      emptyIcon={<CbhIcon type="star" size={ratingSizeToIconSize[size]} />}
    />
  );
}
